<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-10 text-center">
    <v-card max-width="450" class="mx-auto">
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
          <span class=""> {{ $t("users.changePassword") }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="changePasswordItem.oldPassword"
                :label="$t('users.oldPassword')"
                :rules="[rules.required]"
                type="password"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="changePasswordItem.password"
                :label="$t('users.newPassword')"
                :rules="[rules.required]"
                type="password"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                type="password"
                v-model="changePasswordItem.confirmPassword"
                :rules="[rules.required, rules.confirmPassword]"
                :label="$t('users.confirmPassword')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="green darken-1"
          class="white--text"
          @click="saveChangePassword"
          :disabled="!valid"
          :min-width="100"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      changePasswordItem: {
        oldPassword: "",
        password: "",
        confirmPassword: "",
      },

      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
          this.changePasswordItem.confirmPassword ===
            this.changePasswordItem.password ||
          this.$t("users.notMatchPassword"),
      },
    };
  },
  methods: {
    saveChangePassword() {
      var val = this.$refs.form.validate();
      if (val) {
        axios
          .post("Account/ChangePasswordByUser", this.changePasswordItem)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.$router.push({ path: "/" });
              //window.location.href = "/";
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
